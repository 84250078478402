import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AppService } from "src/app/Utils/service/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { common } from "src/app/app.common";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RequestBase } from "src/app/Models/input.model/RequestBase";
import { ClassService } from "src/app/Utils/service/class.service";
import { SeasonService } from "src/app/Utils/service/season.service";
import { ReportService } from "src/app/Utils/service/report.service";
import {
  ResponseClassListAll,
  ClassInfo,
} from "src/app/Models/output.model/ResponseClassListAll";
import {
  ResponseListSchedule,
  ClassData,
} from "src/app/Models/output.model/ResponseListSchedule";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { RequestTeachingScheduleList } from "src/app/Models/input.model/RequestTeachingScheduleList";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { SurveyService } from "src/app/Utils/service/survey.service";
import { ExportService } from "src/app/Utils/service/export.service";
import { CoreService } from "src/app/Utils/service/core.service";
import { NgxSpinnerService } from "ngx-spinner";
import { LoaiDanhGiaService } from "../Utils/service/loai-danh-gia.service";
@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: ResponseListSchedule = new ResponseListSchedule();
  ClassData: ClassData[];
  requestObject: RequestTeachingScheduleList =
    new RequestTeachingScheduleList();
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  HocKy: number;
  NamHoc: string;
  MonHoc: string;
  LopsSelected: any;
  TieuDeBaoCao: any;
  closeResult: string;
  Title: string = "Đang tải mẫu báo cáo...";
  TitleClass: string = "spinner-border text-muted";
  Modal: any;
  //public Datetime: FormGroup;
  public MaDot: string;
  public TenDot: string;
  public NguoiTao: string;
  public ID_dot_danh_gia: any;
  public SeasonData: any;
  public ListHe: any;
  public ListKhoa: any;
  public ListKhoaGV: any;
  public ListKhoaHoc: any;
  public ListChuyenNganh: any;
  public ListPhieu: any;
  public ListLop: any;
  public ListSeason: any;
  public ListGV: any;
  public KhoasF: any;
  public KhoaHocsF: any;
  public ChuyenNganhsF: any;
  public PhieusF: any;
  public Hocky: any;
  public Tokens: any;
  public token: any;
  public Lop_hanh_chinhs: any;
  public Mon_hocs: any;
  idLoaiDanhGia: any = 0;
  loaiDanhGia: any;

  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    public SurveyService: SurveyService,
    public ReportService: ReportService,
    private ClassService: ClassService,
    private SeasonService: SeasonService,
    private exportService: ExportService,
    private coreService: CoreService,
    private spinner: NgxSpinnerService,
    private loaiDanhGiaSv: LoaiDanhGiaService,
  ) {
    //
  }
  dtTrigger = new Subject();
  public com: common;
  ngOnInit() {
    let element, name, arr;
    element = document.getElementById("menu3");
    arr = element.className.split(" ");
    name = "menu-open";
    if (arr.indexOf(name) == -1) {
      element.className += " " + name;
    }
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let MaDotdt = data[0];
      let TenDotdt = data[1];
      let NguoiTaodt = data[6];
      let bool1 = true;
      let fillter = true;
      if (this.MaDot != undefined) {
        bool1 = MaDotdt.includes(this.MaDot);
      }
      if (this.TenDot != undefined) {
        bool1 = TenDotdt.includes(this.TenDot);
      }
      if (this.NguoiTao != undefined) {
        bool1 = NguoiTaodt.includes(this.NguoiTao);
      }
      fillter = bool1;
      return fillter;
    });
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Tokens = a;
    this.token=a.access_token
    //var rs=this.Class.ClassListAll(a.Info.UserName, a.access_token)
    this.requestObject.UserName = a.Info.UserName;
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,

      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "ID",
          width:"5%"
        },
        {
          title: "Tên báo cáo",
        },
        {
          title: "Thao tác",
          width: "15%",
          className: "dt-center",
        },
      ],
    };
    $("#gvinput").hide();
    this.ChangeThongKeFirst();
    this.SeasonService.getKhoa(a.access_token).subscribe((z) => {
      this.ListKhoaGV= z;
      this.FillKhoaGV(z);
    });
    this.SeasonService.GetHe(a.access_token).subscribe((z) => {
      this.ListHe = z.Data;
      this.SeasonService.GetKhoa(a.access_token).subscribe((z) => {
        this.ListKhoa = z.Data;
        this.SeasonService.GetKhoaHoc(a.access_token).subscribe((z) => {
          this.ListKhoaHoc = z.Data;
          this.SeasonService.GetChuyenNganh(a.access_token).subscribe((z) => {
            this.ListChuyenNganh = z.Data;
            this.HeChange();
          });
          this.SeasonService.getListClass(a.access_token).subscribe((z) => {
            this.ListLop = z.Data;
          });
        });
      });
    });
    this.getLoaiDanhGia();
    this.ReportService.PhieuDanhGia_Load_List_Dot(a.access_token).subscribe((z) => {
      const ls = new Array();
      for (var i = 0; i < z.Data.length; i++) {
        ls.push(z.Data[i].Survey);
      }
      this.ListPhieu = ls;
      this.SeasonService.getListSeason(a.access_token).subscribe((z) => {
        this.ListSeason = z.Data;
      });
      this.SeasonService.getKhoa(a.access_token).subscribe((z) => {
        this.ListKhoaGV = z;
      });
      this.ReportService.DotDanhGia_LoadHocKy(a.access_token).subscribe((z) => {
        this.Hocky = z.Data;
        this.FillNamHoc();
        this.dtTrigger.next();
        this.ClassData = this.Res.Data;
        this.Title = "";
        this.TitleClass = "";
        this.SeasonService.getListClass(a.access_token).subscribe((z) => {
          this.ListLop = z.Data;
        });
      });
    });
  }
  GiangVienChange() {
     
    this.GetLopHoc();
    this.GetMonHoc();
  }
  LopHocChange() {
    this.GetMonHoc();
  }
  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  getListReport(token) {
    this.ReportService.getListReportSV(token).subscribe((z) => {
      this.Res = z;
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  getmodal(id) {
    this.Modal = id;
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "sm" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ClearSelect(id) {
    var html = "";
    $("#" + id + "").html(html);
  }
  HeChange() {
    var HeVal = +$("#He").val();
  
    this.KhoasF = jQuery.grep(this.ListKhoa, function (pa: any, i) {
      return pa.ID_he == HeVal;
    });
  
    if (this.KhoasF.length > 0) {
      let allKhoaHocsF = [];
      let allChuyenNganhsF = [];
  
      for (let i = 0; i < this.KhoasF.length; i++) {
        let ID_khoaft = this.KhoasF[i].ID_khoa;
  
        let khoaHocsTemp = jQuery.grep(this.ListKhoaHoc, function (pa: any, j) {
          return pa.ID_he == HeVal && pa.ID_khoa == ID_khoaft;
        });
  
        if (khoaHocsTemp.length > 0) {
          allKhoaHocsF = allKhoaHocsF.concat(khoaHocsTemp);
  
          for (let j = 0; j < khoaHocsTemp.length; j++) {
            let Khoa_hocft = khoaHocsTemp[j].Khoa_hoc;
            let chuyenNganhsTemp = jQuery.grep(this.ListChuyenNganh, function (pa: any, k) {
              return (
                pa.ID_he == HeVal &&
                pa.ID_khoa == ID_khoaft &&
                pa.Khoa_hoc == Khoa_hocft
              );
            });
  
            allChuyenNganhsF = allChuyenNganhsF.concat(chuyenNganhsTemp);
          }
        }
      }
  
      this.FillKhoaHoc(allKhoaHocsF);
      this.FillChuyenNganh(allChuyenNganhsF);
  
      this.FillKhoa(this.KhoasF);
  
    } else {
      this.ClearSelect("Khoa");
      this.ClearSelect("KhoaHoc");
      this.ClearSelect("ChuyenNganh");
    }
  }
 KhoaChange() {
  var KhoaVal = $("#Khoa").val();
  var HeVal = $("#He").val();
  if (KhoaVal == "0") {
    this.LoadAllKhoaHoc();
    return;
  }
  this.KhoaHocsF = jQuery.grep(this.ListKhoaHoc, function (pa: any, i) {
    return pa.ID_he == HeVal && pa.ID_khoa == KhoaVal;
  });

  if (this.KhoaHocsF.length > 0) {
    let allChuyenNganhsF = [];

    for (let i = 0; i < this.KhoaHocsF.length; i++) {
      let Khoa_hocft = this.KhoaHocsF[i].Khoa_hoc;
      let chuyenNganhsTemp = jQuery.grep(this.ListChuyenNganh, function (pa: any, j) {
        return (
          pa.ID_he == HeVal &&
          pa.ID_khoa == KhoaVal &&
          pa.Khoa_hoc == Khoa_hocft
        );
      });

      allChuyenNganhsF = allChuyenNganhsF.concat(chuyenNganhsTemp);
    }

    this.FillKhoaHoc(this.KhoaHocsF);
    this.FillChuyenNganh(allChuyenNganhsF);
  } else {
    this.ClearSelect("KhoaHoc");
    this.ClearSelect("ChuyenNganh");
  }
}

  KhoaGVChange()
  {
    this.GetGiaoVien();
  }
  KhoahocChange() {
    var KhoaHocVal = $("#KhoaHoc").val();
    var KhoaVal = $("#Khoa").val();
    var HeVal = $("#He").val();
    if (!KhoaVal || KhoaVal === "0") {
      if (KhoaHocVal == "0") {
        this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function (pa: any) {
          return pa.ID_he == HeVal;
        });
      } else {
        this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function (pa: any) {
          return pa.ID_he == HeVal && pa.Khoa_hoc == KhoaHocVal;
        });
      }
    } else {
      if (KhoaHocVal == "0") {
        this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function (pa: any) {
          return pa.ID_he == HeVal && pa.ID_khoa == KhoaVal;
        });
      } else {
        this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function (pa: any) {
          return pa.ID_he == HeVal && pa.ID_khoa == KhoaVal && pa.Khoa_hoc == KhoaHocVal;
        });
      }
    }
  
    if (this.ChuyenNganhsF.length > 0) {
      this.FillChuyenNganh(this.ChuyenNganhsF);
    } else {
      this.ClearSelect("ChuyenNganh");
    }
  }
  LoadAllKhoaHoc() {
    var HeVal = +$("#He").val(); 
    this.KhoaHocsF = jQuery.grep(this.ListKhoaHoc, function(pa: any) {
      return pa.ID_he == HeVal;
    });
    this.FillKhoaHoc(this.KhoaHocsF);
    
    this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function(pa: any) {
      return pa.ID_he == HeVal;
    });
    this.FillChuyenNganh(this.ChuyenNganhsF);
  }
  
  LoadAllChuyenNganh() {
    var HeVal = +$("#He").val(); 
    this.ChuyenNganhsF = jQuery.grep(this.ListChuyenNganh, function(pa: any) {
      return pa.ID_he == HeVal;
    });
    
    this.FillChuyenNganh(this.ChuyenNganhsF);
  }
  
  
  FillKhoaHoc(data) {
    var html = "";
    var seenKhoaHoc = {}; 
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        if (!seenKhoaHoc[data[k].Khoa_hoc]) {
          seenKhoaHoc[data[k].Khoa_hoc] = true;
          html +=
            '<option value="' +
            data[k].Khoa_hoc +
            '">' +
            data[k].Khoa_hoc +
            "</option>";
        }
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#KhoaHoc").html(html);
    
    var selectedValue = $("#KhoaHoc").val();
    if (selectedValue == "0") {
      this.LoadAllChuyenNganh(); 
    } else {
      this.KhoahocChange(); 
    }
  }
  
  
  FillKhoa(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_khoa +
          '">' +
          data[k].Ten_khoa +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#Khoa").html(html);
    var selectedValue = $("#Khoa").val();
    if (selectedValue == "0") {
      this.LoadAllKhoaHoc();
    } else {
      this.KhoaChange();
    }
  }
  FillChuyenNganh(data) {
    var html = "";
    var seenChuyenNganh = {};
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        if (!seenChuyenNganh[data[k].ID_chuyen_nganh]) {
          seenChuyenNganh[data[k].ID_chuyen_nganh] = true;
          html +=
            '<option value="' +
            data[k].ID_chuyen_nganh +
            '">' +
            data[k].Chuyen_nganh +
            "</option>";
        }
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#ChuyenNganh").html(html);
  }
  FillKhoaGV(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_khoa +
          '">' +
          data[k].Ten_khoa +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#Don_vi_GV").html(html);
  }

  NamHocChange() {
    var NamHocVal = $("#namhoc").val();
    var makearray = jQuery.grep(this.Hocky, function (pa: any, i) {
      return pa.Nam_hoc == NamHocVal;
    });
    this.FillHocKy(NamHocVal);
    this.FillDotDanhGia(NamHocVal, makearray[0].Hoc_ky);
  }
  FillNamHoc() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.ReportService.DotDanhGia_LoadNam(a.access_token).subscribe((z) => {
      let ListNam = z.Data;
      var html = "";
      for (var i = 0; i < Object.values(ListNam).length; i++) {
        html +=
          '<option value="' +
          ListNam[i].Nam_hoc +
          '">' +
          ListNam[i].Nam_hoc +
          "</option>";
      }
      $("#namhoc").html(html);
      this.FillHocKy(ListNam[0].Nam_hoc);
      this.FillDotDanhGia(ListNam[0].Nam_hoc, this.Hocky[0].Hoc_ky);
      // this.FillPhieuDanhGia(this.ListPhieu, this.ListSeason[0].ID_dot_danh_gia);
    });
  }
  FillHocKy(j) {
    var makearray = jQuery.grep(this.Hocky, function (pa: any, i) {
      return pa.Nam_hoc == j;
    });

    var html = "";
    for (var k in makearray) {
      html +=
        '<option value="' +
        makearray[k].Hoc_ky +
        '">' +
        "Học kỳ " +
        makearray[k].Hoc_ky +
        "</option>";
    }
    $("#hocky").html(html);
  }
  FillDotDanhGia(n, h) {
    var makearray = jQuery.grep(this.ListSeason, function (pa: any, i) {
      return pa.Nam_hoc == n;
    });
    makearray = jQuery.grep(makearray, function (pa: any, i) {
      return pa.Hoc_ky == h;
    });

    var html = "";
    for (var k in makearray) {
      html +=
        '<option value="' +
        makearray[k].ID_dot_danh_gia +
        '">' +
        makearray[k].Ten_dot +
        "</option>";
    }
    $("#dotdanhgia").html(html);

    this.FillPhieuDanhGia(this.ListPhieu, makearray[0].ID_dot_danh_gia);
  }
  FillPhieuDanhGia(data, p) {
    var makearray = jQuery.grep(data, function (pa: any, i) {
      return pa.ID_dot_danh_gia == p;
    });
    var html = "";
    for (var k in makearray) {
      html +=
        '<option value="' +
        makearray[k].ID_phieu_danh_gia +
        '">' +
        makearray[k].Ten_phieu +
        "</option>";
    }
    $("#phieudanhgia").html(html);
  }
  HocKyChange() {
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    this.FillDotDanhGia(NamHocVal, HocKyVal);
  }
  DotDanhGiaChange() {
    var DotDanhGiaVal = $("#dotdanhgia").val();
    this.FillPhieuDanhGia(this.ListPhieu, DotDanhGiaVal);
  }
  ChangeThongKeOld() {
    $(".gvinput").hide();
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    var loai = $("#loaidanhgia").val();
    if (loai == 1) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.ReportService.getListReportSV(a.access_token).subscribe((z) => {
        this.Res = z;
        this.dtTrigger.next();
        this.ClassData = this.Res.Data;
        this.Title = "";
        this.TitleClass = "";
      });
    } else if (loai == 2) {
      $(".gvinput").show();
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.ReportService.getListReport(a.access_token).subscribe((z) => {
        this.Res = z;
        this.dtTrigger.next();
        this.ClassData = this.Res.Data;
        this.Title = "";
        this.TitleClass = "";
      });
    } else if (loai == 3) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.ReportService.getListReportDN(a.access_token).subscribe((z) => {
        this.Res = z;
        this.dtTrigger.next();
        this.ClassData = this.Res.Data;
        this.Title = "";
        this.TitleClass = "";
      });
    } else {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.ReportService.getListReportKSGV(a.access_token).subscribe((z) => {
        this.Res = z;
        this.dtTrigger.next();
        this.ClassData = this.Res.Data;
        this.Title = "";
        this.TitleClass = "";
      });
    }
  }

  ChangeThongKe(){
    var loaiDanhGia = $("#loaidanhgia").val();
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    this.ReportService.getListReportFilter(this.token, loaiDanhGia).subscribe((z) => {
      this.Res = z;
      this.dtTrigger.next();
      this.ClassData = this.Res.Data;
      this.Title = "";
      this.TitleClass = "";
    });
  }

  ChangeThongKeFirst(){
    var loaiDanhGia = $("#loaidanhgia").val();
    this.ReportService.getListReportFilter(this.token, loaiDanhGia).subscribe((z) => {
      this.Res = z;
    });
  }
  TaiBaoCao(data) {
    if(data.URL_api == null){

    if (data.Id_Bao_Cao == 1) {
      this.ThongKeGV();
      //Chi tiết kết quả đánh giá theo giảng viên và tiêu chí
    }
    if (data.Id_Bao_Cao == 2) {
      this.ThongKe3();
    }
    if (data.Id_Bao_Cao == 3) {
      this.ThongKe2();
      //Tổng hợp kết quả đánh giá theo học kỳ, năm học
    }
    if (data.Id_Bao_Cao == 4) {
      this.ThongKe4();
    }
    if (data.Id_Bao_Cao == 5) {
      this.ThongKe5();
      //Tổng hợp kết quả đánh giá toàn trường
    }
    if (data.Id_Bao_Cao == 6) {
      this.ThongKe6();
    }
    if (data.Id_Bao_Cao == 7) {
      this.ThongKegiaovienKs();
      //Tổng hợp số lượng phiếu đánh giá theo từng giảng viên
    }
    if (data.Id_Bao_Cao == 8) {
      this.ThongKehocvientraloi();
    }
    if (data.Id_Bao_Cao == 9) {
      this.ThongKeTieuChi();
      //BÁO CÁO KẾT QUẢ TỔNG HỢP THEO TỪNG TIÊU CHÍ
    }
    if (data.Id_Bao_Cao == 10) {
      this.ThongKeNhomTieuChi();
    }
    if (data.Id_Bao_Cao == 11) {
      this.ThongKeketquatheodonvi();
      //Phân loại kết quả đánh giá theo khoa và mức độ đánh giá
    }
    if (data.Id_Bao_Cao == 12) {
      this.ThongKe_Khoa();
      //Tổng hợp kết quả khảo sát theo tiêu chí và khóa học
    }
    if (data.Id_Bao_Cao == 13) {
      this.ThongKe_ChuyenNganh();
      //Tổng hợp kết quả khảo sát theo tiêu chí và chuyên ngành
    }
    if (data.Id_Bao_Cao == 15) {
      this.ThongKeSV();
    }
    if (data.Id_Bao_Cao == 16) {
      this.ThongKe_PhanHoi();
    }
    if (data.Id_Bao_Cao == 17) {
      this.ThongKeGiangVienTieuChi();
    }
    if (data.Id_Bao_Cao == 18) {
      this.ThongKehocvientraloitheolop();
    }
    if (data.Id_Bao_Cao == 22) {
      this.chitiettraloisinhvien_phieukhaosat();
    }
    if (data.Id_Bao_Cao == 23) {
      this.BaoCaoChiTietSV();
      // Báo cáo tổng hợp tất cả các loại câu hỏi, thống kê theo mã sinh viên (phiếu đánh giá)
    }
    if (data.Id_Bao_Cao == 24) {
      this.BaoCaoChiTietSVKS();
      // Báo cáo tổng hợp tất cả các loại câu hỏi, thống kê theo mã sinh viên(phiếu khảo sát)
    }
    if (data.Id_Bao_Cao == 25) {
      this.BaoCaoChiTietDoanhNghiep();
      // Báo cáo tổng hợp tất cả các loại câu hỏi, thống kê theo doanh nghiệp(phiếu khảo sát)
    }
    if (data.Id_Bao_Cao == 26) {
      this.ThongKeGVTieuChiNhom3();
      // Báo cáo tổng hợp tất cả các loại câu hỏi, thống kê theo doanh nghiệp(phiếu khảo sát)
    }
    if (data.Id_Bao_Cao == 29) {
      this.KHAOSATGIANGVIENCTGD();
      // Báo cáo tổng hợp tất cả các loại câu hỏi, thống kê theo doanh nghiệp(phiếu khảo sát)
    }
    if (data.Id_Bao_Cao == 31) {
      this.ThongKeGV_DTB();
    }
    if (data.Id_Bao_Cao  == 32) {
      this.ThongKeNienChe_ChiTietGiangVien();
    }
    if (data.Id_Bao_Cao  == 33) {
      this.ThongKeNienChe_TongHopGiangVien();
    }
    if (data.Id_Bao_Cao  == 34) {
      this.ThongKeNienChe_ChiTietSinhVien();
    }
    if (data.Id_Bao_Cao  == 35) {
      this.ThongKeNienChe_SinhVienToanTruong();
    } 
  }else{
    this.PrintBaoCao(data);
  }
   
  }
  PrintBaoCao(data) {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var HocKyVal = $("#hocky").val();
    var HocKy = $("#hocky").val();
    var NamHoc=  $("#namhoc").val();
    var IDDotDanhGia=  $("#dotdanhgia").val();
    var IDPhieuDanhGia=  $("#phieudanhgia").val();
    var Id_He = $("#He").val();
    var Id_Khoa=  $("#Khoa").val();
    var Id_Khoa_GV=  $("#Don_vi_GV").val();
    var Khoa_Hoc = $("#KhoaHoc").val();
    var id_cb=  $("#GiangVien").val();
    var ID_chuyen_nganh=  $("#ChuyenNganh").val();
    var Ten_khoa = "";
    if (Id_Khoa > 0) {
      const khoa = this.ListKhoaGV.find(x => x.ID_khoa == Id_Khoa);
      Ten_khoa = khoa ? khoa.Ten_khoa : "";
    }
    var checkLoaiPhieu = false;
    if (data.URL_api == "baocao/khao-sat-hoat-dong-giang-day") {
      checkLoaiPhieu = true;
      if (this.idLoaiDanhGia < 1 || DotDanhGiaVal < 1) {
        this.toastr.warning("Bạn chưa chọn loại phiếu hoặc đợt đánh giá");
        return false;
      }
    }
    if ((PhieuDanhGiaVal > 0 || checkLoaiPhieu) && DotDanhGiaVal > 0) {
      var r = confirm(
        "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
      );
      if (r) {
        let req = {
        HocKy: HocKy,
        NamHoc: NamHoc,
        IDDotDanhGia: IDDotDanhGia,
        IDLoaiDanhGia: this.idLoaiDanhGia,
        IDPhieuDanhGia: IDPhieuDanhGia,
        Id_He: Id_He,
        Id_Khoa: Id_Khoa,
        Khoa_Hoc: Khoa_Hoc,
        Ten_Khoa: Ten_khoa,
        id_cb :  id_cb,
        ID_chuyen_nganh: ID_chuyen_nganh,
        Id_Khoa_GV:Id_Khoa_GV
        }
        this.spinner.show();
        this.ReportService.PrintBaoCao(
          req,
          this.Tokens.access_token,
          data.URL_api
        ).subscribe((z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(
              z.FileData,
              data.Ten_Bao_Cao
            );
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, "Tác vụ thất bại");
          }
        });
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu hoặc đợt đánh giá");
    }
  }
  GetLopHoc() {
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
    var KhoaVal = $("#Khoa").val();
    this.coreService
      .GetLopHoc(
        HocKyVal,
        NamHocVal,
        GiangVienVal,
        Mon_hocVal,
        KhoaVal,
        this.Tokens.access_token
      )
      .subscribe((z) => {
        if (z.Status == 1) {
          this.Lop_hanh_chinhs = z.Data;
        } else {
          this.toastr.error(
            z.Message,
            "Không thể lấy danh sách lớp hành chính"
          );
        }
      });
  }
  GetMonHoc() {
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    var Lop_hocVal = $("#Lop_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
     
    this.coreService
      .GetMonHoc(
        HocKyVal,
        NamHocVal,
        GiangVienVal,
        Lop_hocVal,
        this.Tokens.access_token
      )
      .subscribe((z) => {
        if (z.Status == 1) {
          this.Mon_hocs = z.Data;
        } else {
          this.toastr.error(
            z.Message,
            "Không thể lấy danh sách lớp hành chính"
          );
        }
      });
  }
  ThongKeGiangVienTieuChi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var HeVal = $("#He").val();
    var GiangVienVal = $("#GiangVien").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      if (HeVal) {
        if (GiangVienVal > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            let req = {
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
              IDDotDanhGia: DotDanhGiaVal,
              IDPhieuDanhGia: PhieuDanhGiaVal,
              Id_He: HeVal,
              id_cb: GiangVienVal,
              NamHoc: NamHoc,
              HocKy: HocKy,
              Ten_mon: $("#Mon_hoc option:selected").text(),
              ID_mon: Mon_hocVal,
            };
            this.spinner.show();
            this.ReportService.ThongKeGiangVienTheoTieuChi(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKeGiangVienTieuChi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn giảng viên cần thống kê");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn hệ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu hoặc đợt đánh giá");
    }
  }
  ThongKehocvientraloitheolop() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
   
      var r = confirm(
        "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
      );
      if (r) {
        let req = {
          Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
          IDDotDanhGia: DotDanhGiaVal,
          IDPhieuDanhGia: PhieuDanhGiaVal,
        };
        this.spinner.show();
        this.ReportService.ThongKehocvientraloitheolop(
          req,
          this.Tokens.access_token
        ).subscribe((z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(
              z.FileData,
              "ThongKeSoLuotHV_TraLoi"
            );
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, "Tác vụ thất bại");
          }
        });
      }
  }

  ThongKe_CauHoi() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      let r = confirm("Bạn có muốn tải xuống file thống kê?");
      if (r) {
        this.GetLopsSelected();
        if (this.LopsSelected.length > 0) {
          let req = {
            IDLops: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            UserName: this.Tokens.Info.UserName,
            TieuDeBaoCao: this.TieuDeBaoCao,
          };
          this.spinner.show();
          this.ReportService.ThongKe_CauHoi(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(z.FileData, "ThongKe");
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        }
      } else {
        this.toastr.warning("Không có lớp nào được chọn");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe_Khoa() {
    let r = confirm("Bạn có muốn tải xuống file thống kê khoa?");
    if (r) {
      var PhieuDanhGiaVal = $("#phieudanhgia").val();
      var DotDanhGiaVal = $("#dotdanhgia").val();
      if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
        this.GetLopsSelected();
        if (this.LopsSelected.length > 0) {
          let req = {
            IDLops: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            UserName: this.Tokens.Info.UserName,
            TieuDeBaoCao: this.TieuDeBaoCao,
          };
          this.spinner.show();
          this.ReportService.ThongKe_Khoa(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(z.FileData, "ThongKe_Khoa");
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        } else {
          this.toastr.warning("Không có lớp nào được chọn");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
      }
    }
  }
  ThongKe_ChuyenNganh() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      let r = confirm("Bạn có muốn tải xuống file thống kê?");
      if (r) {
        this.GetLopsSelected();
        if (this.LopsSelected.length > 0) {
          let req = {
            IDLops: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            UserName: this.Tokens.Info.UserName,
            TieuDeBaoCao: this.TieuDeBaoCao,
          };
          this.spinner.show();
          this.ReportService.ThongKe_ChuyenNganh(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "ThongKe_ChuyenNganh"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Không có lớp nào được chọn");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeSV() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      let r = confirm("Bạn có muốn tải xuống file thống kê?");
      if (r) {
        this.GetLopsSelected();
        if (this.LopsSelected.length > 0) {
          let req = {
            IDLops: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            UserName: this.Tokens.Info.UserName,
            TieuDeBaoCao: this.TieuDeBaoCao,
          };
          this.spinner.show();
          this.ReportService.ThongKe(req, this.Tokens.access_token).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(z.FileData, "ThongKe");
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Không có lớp nào được chọn");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe_PhanHoi() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      let r = confirm("Bạn có muốn tải xuống file thống kê?");
      if (r) {
        this.GetLopsSelected();
        if (this.LopsSelected.length > 0) {
          let req = {
            IDLops: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            UserName: this.Tokens.Info.UserName,
            TieuDeBaoCao: this.TieuDeBaoCao,
          };
          this.spinner.show();
          this.ReportService.ThongKe_PhanHoi(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKe_PhanHoi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        }
      } else {
        this.toastr.warning("Không có lớp nào được chọn");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeGV() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.ThongKeGV(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(z.FileData, "ThongKeGV");
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeNienChe_ChiTietGiangVien() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
    var Lop_hocVal = $("#Lop_hoc").val();
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    //if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0 && Mon_hocVal > 0 && GiangVienVal > 0 && Lop_hocVal > 0)
    //Yêu cầu riêng của bên Đại học Đại Nam cho phép chọn tất cả giảng viên, lớp, môn học
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      var Req = {
        Ten_mon: $("#Mon_hoc option:selected").text(),
        ID_mon: Mon_hocVal,
        Ho_ten: $("#GiangVien option:selected").text(),
        ID_cb: GiangVienVal,
        Ten_lop: $("#Lop_hoc option:selected").text(),
        ID_lop: Lop_hocVal,
        ID_phieu: PhieuDanhGiaVal,
        ID_dot: DotDanhGiaVal,
        ID_namhoc: NamHocVal,
        ID_hocky: HocKyVal,
      };
      this.spinner.show();
      this.ReportService.ThongKeNienCHe_ChiTiet_GiangVien(
        Req,
        this.Tokens.access_token
      ).subscribe((z) => {
         
        this.spinner.hide();
        for (var k = 0; k < z.length; k++) {
          if (z[k].Status == 1) {
            this.exportService.exportFileByte(z[k].FileData, z[k].FileName);
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, "Tác vụ thất bại");
          }
        }
      });
    } else {
      //this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt, giáo viên, lớp hành chính và môn học để thống kê")
      this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt để thống kê");
    }
  }
  ThongKeNienChe_TongHopGiangVien() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
    var Lop_hocVal = $("#Lop_hoc").val();
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal) {
      var Req = {
        ID_mon: Mon_hocVal,
        ID_cb: GiangVienVal,
        ID_lop: Lop_hocVal,
        ID_phieu: PhieuDanhGiaVal,
        ID_dot: DotDanhGiaVal,
      };
      this.spinner.show();
      this.ReportService.ThongKeNienCHe_TongHop_GiangVien(
        Req,
        this.Tokens.access_token
      ).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 1) {
          this.exportService.exportExcelByte(
            z.FileData,
            "ThongKeNienCHe_TongHop_GiangVien"
          );
          this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, "Tác vụ thất bại");
        }
      });
    } else {
      this.toastr.warning(
        "Vui lòng chọn đầy đủ phiếu và đợt để thống kê cho mẫu này"
      );
    }
  }
  ThongKeNienChe_ChiTietSinhVien() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
    var Lop_hocVal = $("#Lop_hoc").val();
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    var KhoaVal = $("#Khoa").val();
    //if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0 && Mon_hocVal > 0 && GiangVienVal > 0 && Lop_hocVal > 0)
    //Yêu cầu riêng của bên Đại học Đại Nam cho phép chọn tất cả giảng viên, lớp, môn học
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0 && KhoaVal > 0) {
      var Req = {
        Ten_mon: $("#Mon_hoc option:selected").text(),
        ID_mon: Mon_hocVal,
        Ho_ten: $("#GiangVien option:selected").text(),
        ID_cb: GiangVienVal,
        Ten_lop: $("#Lop_hoc option:selected").text(),
        ID_lop: Lop_hocVal,
        ID_phieu: PhieuDanhGiaVal,
        ID_dot: DotDanhGiaVal,
        ID_namhoc: NamHocVal,
        ID_hocky: HocKyVal,
        ID_Khoa: KhoaVal,
        Ten_Khoa: $("#Khoa option:selected").text(),
      };
      this.spinner.show();
      this.ReportService.ThongKeNienCHe_ChiTiet_SinhVien(
        Req,
        this.Tokens.access_token
      ).subscribe((z) => {
         
        this.spinner.hide();
        for (var k = 0; k < z.length; k++) {
          if (z[k].Status == 1) {
            this.exportService.exportFileByte(z[k].FileData, z[k].FileName);
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, "Tác vụ thất bại");
          }
        }
      });
    } else {
      //this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt, giáo viên, lớp hành chính và môn học để thống kê")
      this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt, Khoa để thống kê");
    }
  }

  ThongKeNienChe_SinhVienToanTruong() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Mon_hocVal = $("#Mon_hoc").val();
    var GiangVienVal = $("#GiangVien").val();
    var Lop_hocVal = $("#Lop_hoc").val();
    var NamHocVal = $("#namhoc").val();
    var HocKyVal = $("#hocky").val();
    //if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0 && Mon_hocVal > 0 && GiangVienVal > 0 && Lop_hocVal > 0)
    //Yêu cầu riêng của bên Đại học Đại Nam cho phép chọn tất cả giảng viên, lớp, môn học
    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      var Req = {
        Ten_mon: $("#Mon_hoc option:selected").text(),
        ID_mon: Mon_hocVal,
        Ho_ten: $("#GiangVien option:selected").text(),
        ID_cb: GiangVienVal,
        Ten_lop: $("#Lop_hoc option:selected").text(),
        ID_lop: Lop_hocVal,
        ID_phieu: PhieuDanhGiaVal,
        ID_dot: DotDanhGiaVal,
        ID_namhoc: NamHocVal,
        ID_hocky: HocKyVal,
      };
      this.spinner.show();
      this.ReportService.ThongKeNienCHe_SinhVienToanTruong(
        Req,
        this.Tokens.access_token
      ).subscribe((z) => {
         
        this.spinner.hide();

        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, z.FileName);
          this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
          this.spinner.hide();
        } else {
          this.toastr.error(z.Message, "Tác vụ thất bại");
        }
      });
    } else {
      //this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt, giáo viên, lớp hành chính và môn học để thống kê")
      this.toastr.warning("Vui lòng chọn đầy đủ phiếu, đợt để thống kê");
    }
  }

  ThongKeGV_DTB() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.ThongKeGV_DTB(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(z.FileData, "ThongKeGV");
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeGVTieuChiNhom3() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.ThongKeGVTieuChiNhom3(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(z.FileData, "ThongKeGV");
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe3() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    var Heval = $("#He").val();
    var KhoaVal = $("#Khoa").val();
    var KhoaHocVal = $("#KhoaHoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        if (Heval > 0 && KhoaHocVal > 0 && KhoaVal > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            this.spinner.show();
            let req = {
              IDPhieuDanhGia: PhieuDanhGiaVal,
              NamHoc: NamHoc,
              HocKy: HocKy,
              Id_He: Heval,
              Id_Khoa: KhoaVal,
              Khoa_Hoc: KhoaHocVal,
            };
            this.ReportService.KhaoSatGiangVienM2(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "KhaoSatGiangVienM2"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Vui lòng chọn Hệ, Khoa, Khoá học");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe2() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    if (PhieuDanhGiaVal > 0) {
      var r = confirm(
        "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
      );
      if (r) {
        this.spinner.show();
        let req = {
          IDPhieuDanhGia: PhieuDanhGiaVal,
        };
        this.ReportService.DanhGiaGiangVien_NamHoc(
          req,
          this.Tokens.access_token
        ).subscribe((z) => {
          if (z.Status == 1) {
            this.exportService.exportExcelByte(
              z.FileData,
              "DanhGiaGiangVien_NamHoc"
            );
            this.toastr.success("File đã được tải xuống", "Tác vụ thành công");
            this.spinner.hide();
          } else {
            this.toastr.error(z.Message, "Tác vụ thất bại");
          }
        });
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe4() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.KhaoSatGiangVienM3(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "KhaoSatGiangVienM3"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe5() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.KetQuaDanhGiaToanTruong(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "KetQuaDanhGiaToanTruong"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe6() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0) {
      if (DotDanhGiaVal > 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          let req = {
            Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
          };
          this.spinner.show();
          this.ReportService.KhaoSatToanTruong(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "KhaoSatToanTruong"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKegiaovienKs() {
     
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal != "") {
      if (DotDanhGiaVal > 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          let req = {
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
          };
          this.spinner.show();
          this.ReportService.ThongKegiaovienKs(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "ThongKegiaovienKs"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  KHAOSATGIANGVIENCTGD() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal != "" && HocKy != "" && NamHoc != "") {
      if (DotDanhGiaVal > 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          let req = {
            IDDotDanhGia: DotDanhGiaVal,
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.spinner.show();
          this.ReportService.KHAOSATGIANGVIENCTGD(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "ThongKegiaovienKs"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKehocvientraloi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    if (PhieuDanhGiaVal > 0) {
      var r = confirm(
        "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
      );
      if (r) {
        let req = {
          Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
          IDDotDanhGia: DotDanhGiaVal,
          IDPhieuDanhGia: PhieuDanhGiaVal,
        };
        this.spinner.show();
        this.ReportService.ThongKeHV_TraLoi(req, this.Tokens.access_token).subscribe(
          (z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "ThongKeHV_TraLoi"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          }
        );
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeTieuChi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Heval = $("#He").val();

    if (PhieuDanhGiaVal > 0) {
      if (DotDanhGiaVal > 0) {
        if (Heval > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            let req = {
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
              IDDotDanhGia: DotDanhGiaVal,
              IDPhieuDanhGia: PhieuDanhGiaVal,
              Id_He: Heval,
            };
            this.spinner.show();
            this.ReportService.ThongKeTieu_Chi(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKeTieu_Chi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn hệ");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeNhomTieuChi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Heval = $("#He").val();
    if (PhieuDanhGiaVal > 0) {
      if (DotDanhGiaVal > 0) {
        if (Heval > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            let req = {
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
              IDDotDanhGia: DotDanhGiaVal,
              IDPhieuDanhGia: PhieuDanhGiaVal,
              Id_He: Heval,
            };
            this.spinner.show();
            this.ReportService.ThongKeNhomTieu_Chi(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKeNhomTieu_Chi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn hệ");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeketquatheodonvi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var Heval = $("#He").val();
    if (PhieuDanhGiaVal > 0) {
      if (DotDanhGiaVal > 0) {
        if (Heval > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            let req = {
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
              IDDotDanhGia: DotDanhGiaVal,
              IDPhieuDanhGia: PhieuDanhGiaVal,
              Id_He: Heval,
            };
             
            this.spinner.show();
            this.ReportService.ThongKeketquatheodonvi(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKeketquatheodonvi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn hệ");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn đợt đánh giá");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeGVNienChe() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
          };
          this.ReportService.KhaoSatGiangVien_NienChe(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "KhaoSatGiangVien_NienChe"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKe3SinhVienNienChe() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    var Heval = $("#He").val();
    var KhoaVal = $("#Khoa").val();
    var KhoaHocVal = $("#KhoaHoc").val();
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        if (Heval > 0 && KhoaHocVal > 0 && KhoaVal > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            this.spinner.show();
            let req = {
              IDPhieuDanhGia: PhieuDanhGiaVal,
              NamHoc: NamHoc,
              HocKy: HocKy,
              Id_He: Heval,
              Id_Khoa: KhoaVal,
              Khoa_Hoc: KhoaHocVal,
            };
            this.ReportService.KhaoSatGiangVienM2_NienChe(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "KhaoSatGiangVienM2_NienChe"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Vui lòng chọn Hệ, Khoa, Khoá học");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  ThongKeGiangVienNhomTieuChi() {
    this.GetLopsSelected();
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var DotDanhGiaVal = $("#dotdanhgia").val();
    var HeVal = $("#He").val();
    var GiangVienVal = $("#GiangVien").val();

    if (PhieuDanhGiaVal > 0 && DotDanhGiaVal > 0) {
      if (HeVal) {
        if (GiangVienVal > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            this.spinner.show();
            let req = {
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
              IDDotDanhGia: DotDanhGiaVal,
              IDPhieuDanhGia: PhieuDanhGiaVal,
              Id_He: HeVal,
              id_cb: GiangVienVal,
            };
            this.ReportService.ThongKeGiangVienTheoNhomTieuChi(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "ThongKeGiangVienTheoNhomTieuChi"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn giảng viên cần thống kê");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn hệ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu hoặc đợt đánh giá");
    }
  }
  GetLopsSelected() {
    var ChuyenNganhVal = $("#ChuyenNganh").val();
    var KhoaHocVal = $("#KhoaHoc").val();
    var KhoaVal = $("#Khoa").val();
    var HeVal = $("#He").val();
    this.LopsSelected = this.ListLop;
    if (HeVal > 0) {
      this.LopsSelected = jQuery.grep(this.LopsSelected, function (pa: any, i) {
        return pa.ID_he == HeVal;
      });
      this.TieuDeBaoCao = "Hệ " + $("#He option:selected").text();
      if (KhoaVal > 0) {
        this.LopsSelected = jQuery.grep(
          this.LopsSelected,
          function (pa: any, i) {
            return pa.ID_khoa == KhoaVal;
          }
        );
        this.TieuDeBaoCao += " Khoa " + $("#Khoa option:selected").text();
        if (KhoaHocVal > 0) {
          this.LopsSelected = jQuery.grep(
            this.LopsSelected,
            function (pa: any, i) {
              return pa.Khoa_hoc == KhoaHocVal;
            }
          );
          this.TieuDeBaoCao +=
            " Khóa học " + $("#KhoaHoc option:selected").text();
          if (ChuyenNganhVal > 0) {
            this.LopsSelected = jQuery.grep(
              this.LopsSelected,
              function (pa: any, i) {
                return pa.ID_chuyen_nganh == ChuyenNganhVal;
              }
            );
            this.TieuDeBaoCao +=
              " Chuyên ngành " + $("#ChuyenNganh option:selected").text();
          }
        }
      }
    }
  }
  GetStringIDLops(m) {
    var rs = "";
    for (var k = 0; k < m.length; k++) {
      if (k == 0) {
        rs += m[k].ID_lop;
      } else {
        rs += "," + m[k].ID_lop;
      }
    }
    return rs;
  }
  chitiettraloisinhvien_phieukhaosat() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();

    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.GetLopsSelected();
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
            Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
            // IDPhieuDanhGia: 3,
            // NamHoc: '2018-2019',
            // HocKy: 2,
            // Ds_ID_Lop: '1353',
          };
          this.ReportService.chitiettraloisinhvien_pks(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "chitiettraloisinhvien_pks"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  BaoCaoChiTietSV() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();
    var Khoa = $("#Khoa").val();
    ;
    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        if (Khoa > 0) {
          var r = confirm(
            "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
          );
          if (r) {
            this.GetLopsSelected();
            this.spinner.show();
            let req = {
              IDPhieuDanhGia: PhieuDanhGiaVal,
              NamHoc: NamHoc,
              HocKy: HocKy,
              Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
            };
             
            this.ReportService.BaoCaoChiTietSVDG(
              req,
              this.Tokens.access_token
            ).subscribe((z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "BaoCaoChiTietSV"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            });
          }
        } else {
          this.toastr.warning("Bạn chưa chọn Khoa");
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  BaoCaoChiTietSVKS() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();

    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.GetLopsSelected();
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
            Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
          };
          this.ReportService.BaoCaoChiTietSVKS(
            req,
            this.Tokens.access_token
          ).subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(
                z.FileData,
                "BaoCaoChiTietSV_KS"
              );
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  BaoCaoChiTietDoanhNghiep() {
    var PhieuDanhGiaVal = $("#phieudanhgia").val();
    var HocKy = $("#hocky").val();
    var NamHoc = $("#namhoc").val();

    if (PhieuDanhGiaVal > 0) {
      if (HocKy > 0 && NamHoc != 0) {
        var r = confirm(
          "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn thống kê dữ liệu cho phiếu này không?"
        );
        if (r) {
          this.GetLopsSelected();
          this.spinner.show();
          let req = {
            IDPhieuDanhGia: PhieuDanhGiaVal,
            NamHoc: NamHoc,
            HocKy: HocKy,
            Ds_ID_Lop: this.GetStringIDLops(this.LopsSelected),
          };
          this.ReportService.BaoCaoChiTietDN(req, this.Tokens.access_token).subscribe(
            (z) => {
              if (z.Status == 1) {
                this.exportService.exportExcelByte(
                  z.FileData,
                  "BaoCaoChiTietDoanhNghiep"
                );
                this.toastr.success(
                  "File đã được tải xuống",
                  "Tác vụ thành công"
                );
                this.spinner.hide();
              } else {
                this.toastr.error(z.Message, "Tác vụ thất bại");
              }
            }
          );
        }
      } else {
        this.toastr.warning("Bạn chưa chọn năm học, học kỳ");
      }
    } else {
      this.toastr.warning("Bạn chưa chọn phiếu đánh giá");
    }
  }
  GetGiaoVien() {
    var a = this.com.getUserinfo();
    var KhoaVal = $("#Don_vi_GV").val();
    this.ReportService.ListGVDetail(a.access_token, KhoaVal).subscribe((z) => {
      this.ListGV = z;
      this.GetLopHoc();
      this.GetMonHoc();
    });
  }
  resetValue(value: any){
      $("#" + value + "").val(0);
  }

  getLoaiDanhGia() {
    this.loaiDanhGiaSv.getAll(this.Tokens.access_token).subscribe((res) => {
      if (res.Status == 1) {
        this.loaiDanhGia = res.ListData;
      }
    });
  }
}
