import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "src/app/Utils/service/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SearchObjectService } from "src/app/Utils/service/search-object.service";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { common } from "src/app/app.common";
import { SeasonService } from "src/app/Utils/service/season.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { filter } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { ReportService } from "src/app/Utils/service/report.service";
import { ExportService } from "src/app/Utils/service/export.service";
@Component({
  selector: "app-search-object",
  templateUrl: "./search-object.component.html",
  styleUrls: ["./search-object.component.scss"],
})
export class SearchObjectComponent implements OnInit {
  dtOptions: DataTables.Settings;
  Res: any;
  Req: any;
  token: any;
  TableData: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string = "Đang tải danh sách thông báo...";
  TitleClass: string = "spinner-border text-muted";
  public ListSeason: any;
  public Doi_tuong: any;
  public ListHe: any;
  public ListKhoa: any;
  public ListKhoa2: any;
  public ListKhoaHoc: any;
  public ListKhoaHoc2: any;
  public ListChuyenNganh: any;
  public ListChuyenNganh2: any;
  public ListLop: any;
  public ListLop2: any;
  public KhoasF: any;
  public KhoaHocsF: any;
  public ChuyenNganhsF: any;
  public isShow: any;
  public Layout: any;
  public He: any = 0;
  public Khoa: any = 0;
  public Loai_ft: any;
  khaosat_ft: any = false;
  isShowLink: any = false;
  //public Khoaoc: any;
  public KhoaHoc: any = 0;
  public ChuyenNganh: any = 0;
  public Lop: any = 0;
  public show: boolean = true;

  Fillter = new FormGroup({
    He: new FormControl(null),
    Khoa: new FormControl(null),
    KhoaHoc: new FormControl(null),
    ChuyenNganh: new FormControl(null),
    Lop: new FormControl(null),
  });
  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private Service: SearchObjectService,
    private reportService: ReportService,
    private SeasonService: SeasonService,
    public spinner: NgxSpinnerService,
    public exportService: ExportService,
  ) {
    //
  }
  dtTrigger = new Subject();
  public com: common;
  async ngOnInit() {
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let Hedt = data[3];
      let Khoadt = data[4];
      let KhoaHocdt = data[5];
      let ChuyenNganhdt = data[6];
      let Lopdt = data[7];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 =true
      let fillter = true;
      if (this.He != 0 && this.He != undefined) {
        bool1 = Hedt == this.He;
      }
      if (this.Khoa != 0 && this.Khoa != undefined) {
        bool2 = Khoadt == this.Khoa;
      }
      if (this.KhoaHoc != 0 && this.KhoaHoc != undefined) {
        bool3 = KhoaHocdt == this.KhoaHoc;
      }
      if (this.ChuyenNganh != 0 && this.ChuyenNganh != undefined) {
        bool4 = ChuyenNganhdt == this.ChuyenNganh;
      }
      if (this.Lop != 0 && this.Lop != undefined) {
        bool5 = Lopdt == this.Lop;
      }
      
      fillter = bool1 && bool2 && bool3 && bool4 && bool5;
      return fillter;
    });

    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.token = a.access_token;
    var RequestSearchObjectList = {
      ID: 0,
      Category: 0,
    };
    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 25,
      ordering: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Hãy chọn loại đánh giá và đợt đánh giá!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "STT",
          width: "20px",
        },
        {
          title: "Mã đối tượng",
        },
        {
          title: "Họ và tên",
        },
        {
          title: "hệ",
          visible: false,
        },
        {
          title: "khoa",
          visible: false,
        },
        {
          title: "khoá",
          visible: false,
        },
        {
          title: "lop",
          visible: false,
        },
        {
          title: "Số phiếu",
          className: "dt-center",
          width: "13%",
        },
        {
          title: "Đã đánh giá/Tổng số phiếu",
          className: "dt-center",
          width: "13%",
        },
        {
          title: "Thao tác",
          className: "dt-center",
        },
      ],
    };
    await this.getData(RequestSearchObjectList, a.access_token);
    this.spinner.show();
    this.SeasonService.getListSeason(a.access_token).subscribe((z) => {
      this.ListSeason = z.Data;
    });
    // this.SeasonService.GetHe(a.access_token)
    //   .subscribe(z => {
    //     this.ListHe = z.Data;
    //     this.SeasonService.GetKhoa(a.access_token)
    //       .subscribe(z => {
    //         this.ListKhoa = z.Data;
    //         this.SeasonService.GetKhoaHoc(a.access_token)
    //           .subscribe(z => { this.ListKhoaHoc = z.Data });
    //         this.SeasonService.getListClass(a.access_token)
    //           .subscribe(z => { this.ListLop = z.Data; this.HeChange() });
    //       });
    //   });

    this.SeasonService.GetChuyenNganh(a.access_token).subscribe((z) => {
      this.ListChuyenNganh = z.Data;
      this.SeasonService.GetKhoa(a.access_token).subscribe((z) => {
        this.ListKhoa = z.Data;
        this.SeasonService.GetKhoaHoc(a.access_token).subscribe((z) => {
          this.ListKhoaHoc = z.Data;
          this.SeasonService.getListClass(a.access_token).subscribe((z) => {
            this.ListLop = z.Data;
            this.HeChange();
            this.SeasonService.GetHe(a.access_token).subscribe((z) => {
              this.ListHe = z.Data;
              this.spinner.hide();
            });
          });
        });
      });
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  async getData(input: any, token) {
    this.spinner.show();
    this.Service.SearchObject(input, token).subscribe((z) => {
      this.TableData = z.SurveySend;
      this.dtTrigger.next();
      this.Title = "Danh sách thông báo";
      this.TitleClass = "";
      this.spinner.hide();
    });
  }
  searchObject(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
  ClearSelect(id) {
    var html = "";
    $("#" + id + "").html(html);
  }

  CopyLink(url) {
    navigator.clipboard.writeText(url);
    this.toastr.success("Copy địa chỉ đánh giá thành công!");
  }
  HeChange() {
    let ID_he = this.He;
    if (ID_he > 0) {
      this.ListKhoa2 = this.ListKhoa.filter((g) => g.ID_he == ID_he);
      this.ListKhoaHoc2 = this.ListKhoaHoc.filter((g) => g.ID_he == ID_he);
      this.ListKhoaHoc2 = this.ListKhoaHoc2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
      );
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.ID_he == ID_he);
      this.ListLop2 = this.ListLop.filter((g) => g.ID_he == ID_he);

    } else {
      this.ListKhoa2 = [];
      this.ListKhoaHoc2 = [];
      this.ListLop2 = [];
      this.ListChuyenNganh2= [];
    }
  }

  KhoaChange() {
    let ID_khoa = this.Khoa;
    if (ID_khoa > 0) {
      this.ListKhoaHoc2 = this.ListKhoaHoc.filter((g) => g.ID_khoa == ID_khoa);
      this.ListKhoaHoc2 = this.ListKhoaHoc2.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.Khoa_hoc === item.Khoa_hoc) === i
      );
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.ID_khoa == ID_khoa);
      this.ListLop2 = this.ListLop.filter((g) => g.ID_khoa == ID_khoa);
    
    } else {
      this.ListKhoaHoc2 = [];
      this.ListLop2 = [];
      this.ListChuyenNganh2 = []
    }
  }

  KhoahocChange() {
    let Khoa_hoc = this.KhoaHoc;
    if (Khoa_hoc > 0) {
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.Khoa_hoc == Khoa_hoc);
      this.ListLop2 = this.ListLop.filter((g) => g.Khoa_hoc == Khoa_hoc);
    } else {
      this.ListLop2 = [];
      this.ListChuyenNganh2 = []
    }
  }

  ChuyenNganhChange() {
    let Khoa_hoc = this.KhoaHoc;
    let ID_chuyen_nganh = this.ChuyenNganh;
    if (ID_chuyen_nganh > 0) {
      this.ListLop2 = this.ListLop.filter((g) => g.ID_chuyen_nganh == ID_chuyen_nganh && g.Khoa_hoc == Khoa_hoc);
    } else {
      this.ListLop2 = [];
    }
  }


  FillKhoaHoc(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].Khoa_hoc +
          '">' +
          data[k].Khoa_hoc +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#KhoaHoc").html(html);
  }

  FillKhoa(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_khoa +
          '">' +
          data[k].Ten_khoa +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#Khoa").html(html);
  }

  FillChuyenNganh(data) {
    var html = "";
    if (data.length > 0) {
      html += '<option value="0"> Tất cả </option>';
      for (var k in data) {
        html +=
          '<option value="' +
          data[k].ID_lop +
          '">' +
          data[k].Ten_lop +
          "</option>";
      }
    } else {
      html += '<option value="0"> Không có dữ liệu </option>';
    }
    $("#ChuyenNganh").html(html);
  }
  LoaiDanhGiaChange() {
    var LoaiPhieuDanhGia_select = $("#LoaiPhieuDanhGia").val();
    if (LoaiPhieuDanhGia_select == 1) {
      $("#DaTotNghiepDiv").hide();
    } else {
      $("#DaTotNghiepDiv").show();
    }
  }

  LoaidtChange() {
    var loai_dt = $("#Loai_ft option:selected").val();
    this.khaosat_ft = false;
    document.getElementById("filter").style.display = "none";
    if (loai_dt == 1) {
      document.getElementById("filter").style.display = "block";
    }
    if (loai_dt == 2) {
      this.khaosat_ft = true;
    }

    this.isShowLink = loai_dt == 1;
  }

  Export() {
    var loai_dt = $("#Loai_ft option:selected").val();
    var Dot_ft = $("#Dot_ft option:selected").val();
    if (loai_dt > 0 && Dot_ft > 0) {
      var r = confirm(
        "File của bạn sẽ được tải xuống trong giây lát! \n Bạn có muốn tải xuống không?"
      );
      if (r) {
        var req = {
          ID: Dot_ft,
          Category: loai_dt,
        };
        this.spinner.show();
        this.reportService
          .TraCuuDanhGia_SinhVien_Export(
            req,
            this.token
          )
          .subscribe((z) => {
            if (z.Status == 1) {
              this.exportService.exportExcelByte(z.FileData, "tra-cuu-danh-gia-sinh-vien");
              this.toastr.success(
                "File đã được tải xuống",
                "Tác vụ thành công"
              );
              this.spinner.hide();
            } else {
              this.toastr.error(z.Message, "Tác vụ thất bại");
            }
          });
      }
    } else {
      this.toastr.warning("Bạn chưa chọn đối tượng đánh giá và đợt đánh giá");
    }
  }

  async Filter() {
    let Loai_ft = $("#Loai_ft").val();
    let Dot_ft = $("#Dot_ft").val();
    if (Loai_ft < 0) {
      this.toastr.warning("Bạn chưa chọn loại đối tượng!");
    } else if (Dot_ft < 0) {
      this.toastr.warning("Bạn chưa chọn đợt đánh giá!");
    } else {
      if (Loai_ft == 2) {
        if ($("#khaosat_ft").val() == 0) {
          Loai_ft = 2;
        } else {
          Loai_ft = 4;
        }
      }
      this.com = new common(this.router);
      this.com.CheckLogin();
      var a = this.com.getUserinfo();
      let req = {
        ID: Dot_ft,
        Category: Loai_ft,
      };
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });

      await this.getData(req, a.access_token);
    }
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}
