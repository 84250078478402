<section class="content-header" style="padding: 0.5% !important">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <ol class="breadcrumb float-sm-left">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/']"><i class="fas fa-home"></i> &nbsp;Trang chủ</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Khảo sát lớp hành chính
                    </li>
                </ol>
            </div>
            <div class="col-md-6">
                <button class="btn btn-success btn-sm" style="float: right"  (click)="guiPhieuDanhGia()" >
                    <i class="fa fa-paper-plane"></i> Gửi phiếu
                </button>
                <button class="btn btn-primary btn-sm" style="float: right; margin-right: 5px;"
                    (click)="getLopHanhChinh()">
                    <i class="fa fa-search"></i> Tìm kiếm
                </button>
            </div>
        </div>
    </div>
</section>

<section class="content cus-body">
    <div class="card">
        <form class="form-horizontal" [formGroup]="Fillter">
            <div class="card-header p-2" style="align-content: flex-end">
                <div class="row" style="padding-left: 10px; padding-right: 10px">
                    <div class="col-sm">
                        <label class="col-md-10">Hệ</label>
                        <select class="form-control" formControlName="ID_he"  (change)="getKhoa()">
                            <option value="0">Tất cả</option>
                            <option *ngFor="let p of ListHe" [value]="p.ID_he">
                                {{  p.Ten_he }}
                            </option>

                        </select>
                    </div>
                    <div class="col-sm" >
                        <label class="col-md-10">Khoa</label>
                        <select class="form-control" formControlName="ID_khoa" (change)="getChuyenNganh()">
                            <option value="0">Tất cả</option>
                            <option  *ngFor="let p of ListKhoa" [value]="p.ID_khoa" [class.hidden]="!selectedHeId">
                                {{  p.Ten_khoa }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <label class="col-md-10">Khóa học</label>
                        <select class="form-control" formControlName="Khoa_hoc">
                            <option value="0">Tất cả</option>
                            <option *ngFor="let p of ListKhoaHoc" [value]="p.Khoa_hoc">
                                {{  p.Khoa_hoc }}
                            </option>

                        </select>
                    </div>
                    <div class="col-sm">
                        <label class="col-md-10">Chuyên ngành</label>
                        <select class="form-control" formControlName="ID_chuyen_nganh" (change)="getLopHanhChinh()">
                            <option value="0">Chọn chuyên ngành</option>
                            <option *ngFor="let p of ListChuyenNganh" [value]="p.ID_chuyen_nganh">
                                {{  p.Chuyen_nganh }}
                            </option>
                        </select>
                    </div>
                </div>
                
                <div class="row" style="padding-left: 10px; padding-right: 10px">
                    <div class="col-sm">
                        <label class="col-md-10">Lớp</label>
                        <select class="form-control" formControlName="ID_lop">
                            <option value="0">Tất cả</option>
                            <option *ngFor="let p of listLopHanhChinhCombobox" [value]="p.ID_lop">
                                {{  p.Ten_lop }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <label class="col-md-10">Đợt đánh giá</label>
                        <select class="form-control" formControlName="ID_dot_danh_gia">
                            <option *ngFor="let p of dotDanhGia" [value]="p.ID_dot_danh_gia">
                                {{ p.Ten_dot }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm">
                        <label class="col-md-10">Loại phiếu đánh giá</label>
                        <select class="form-control" formControlName="ID_loai_danh_gia" (change)="filterPhieuDanhGia()">
                            <option value="-1">Tất cả</option>
                            <option value="0">Chưa phân loại</option>
                            <option *ngFor="let p of loaiDanhGia" [value]="p.ID_loai_danh_gia">
                                {{ p.Ten_loai_danh_gia }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <nz-form-item nz-col [nzSpan]="24">
                            <label>Phiếu đánh giá</label>
                            <nz-form-control>
                                <nz-select class="custom-nz-select" formControlName="ID_phieu_danh_gia" nzShowSearch nzAllowClear nzDropdownMatchSelectWidth="false" nzSize="large">
                                <nz-option  [nzValue]="0" nzLabel="Chọn phiếu đánh giá" style="width: 100%;"></nz-option>
                                <nz-option 
                                    *ngFor="let p of phieuDanhGiaFilter" 
                                    [nzValue]="p.ID_phieu_danh_gia" 
                                    [nzLabel]="p.Ma_phieu_danh_gia ? '[' + p.Ma_phieu_danh_gia + '] ' + p.Ten_phieu : p.Ten_phieu">
                                </nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end;  padding-right: 10vh;  gap: 20vh">
                  
                        <div  class="form-group"  id="DaRaTruongDiv" style="margin-top: 30px;">
                            <input type="checkbox" class="form-check-input" id="DaRaTruong" formControlName="Da_ra_truong" (change)="getLopHanhChinh()"/>
                            <label class="form-check-label" for="DaRaTruong">Đã ra trường</label>
                        </div>
                        <div  class="form-group" id="DaTotNghiepDiv" style="margin-top: 30px; text-align: right;">
                            <input type="checkbox" class="form-check-input" id="DaTotNghiep" formControlName="Da_tot_nghiep"/>
                            <label class="form-check-label" for="DaTotNghiep">Đã tốt nghiệp</label>
                        </div>
                
                </div>

         
                
            </div>
            
            
        </form>
        <div class="card-body">
            <div class="tab-content">
                <div class="active tab-pane" id="TeachInfo">
                    <div class="row">
                        <div class="col-md-12" style="margin-bottom: 5px">

                            <button class="btn btn-primary btn-sm"  (click)="checkAll()" >
                                Chọn tất cả (Đã chọn: {{ ChonCount }})
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="height: 60vh; overflow: scroll;">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col"  class="text-center">Chọn</th>
                                        <th scope="col"  class="text-center">Tên lớp</th>
                                        <th scope="col"  class="text-center">Số sinh viên</th>
                                        <th scope="col"  class="text-center">Tên khoa</th>
                                        <th scope="col"  class="text-center">Khóa học</th>
                                        <th scope="col"  class="text-center">Chuyên ngành</th>                             
                                        <th scope="col"  class="text-center">Từ ngày</th>
                                        <th scope="col"  class="text-center">Đến ngày</th>
                                        <th scope="col"  class="text-center">Đã gửi</th>
                                        <th scope="col"  class="text-center">Đã đánh giá</th>
                                        <th scope="col"  class="text-center">Tác vụ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of listLopHanhChinh; let i = index">
                                        <td style="text-align: center;">
                                          <input
                                            type="checkbox"
                                            [(checked)]="p.Chon"
                                            (change)="checkOne(i)"
                                          />
                                        </td>
                                        <td>{{ p.Ten_lop }}</td>
                                        <td  class="text-center">{{ p.So_sv }}</td>
                                        <td>{{ p.Ten_khoa }}</td>
                                        <td  class="text-center">{{ p.Khoa_hoc }}</td>    
                                        <td  class="text-center">{{ p.Chuyen_nganh }}</td>                
                                        <td  class="text-center">{{ p.Ngay_bat_dau | date:'dd/MM/yyyy'}}</td>
                                        <td  class="text-center">{{ p.Ngay_ket_thuc | date:'dd/MM/yyyy'}}</td>
                                        <td  class="text-center">{{ p.Da_gui }}</td>
                                        <td  class="text-center">{{ p.Da_danh_gia }}</td>
                                        <td style="text-align: center; white-space: nowrap;">
                                            <button title="Xoá phiếu đánh giá" (click)="deleteMulti(p.lstSinhVien)" class="btn btn-sm btn-danger" style="margin-right: 5px">
                                                <i class="fas fa-trash-alt"></i></button>
                                            <button title="Xem chi tiết" (click)="open(modalSinhVienDaGui,p)" class="btn btn-primary btn-sm">
                                                <i class="far fa-eye"></i></button>
                                        </td>
                                      </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>

<ng-template #modalSinhVienDaGui let-modal>
    <h3 class="mx-auto" style="margin-top: 20px; margin-bottom: 20px;">Danh sách phiếu đã gửi</h3>
  
    <section class="content cus-body">
      <div class="card">
        <div class="row" style="padding: 10px; height: 50px;">
          <div class="col-sm">
            <p class="col-md-10" style="width: 600px; font-size: 19px; text-shadow: 1px 1px 1px rgba(0,0,0,0.2);">
              {{ listSinhVienDanhGia.Ten_dot }}
            </p>
          </div>
          <div class="col-sm">
            <p><span> Ngày bắt đầu: </span> {{ listSinhVienDanhGia.Ngay_bat_dau }}</p>
          </div>
          <div class="col-sm">
            <p><span> Ngày kết thúc: </span> {{ listSinhVienDanhGia.Ngay_ket_thuc }}</p>
          </div>
        </div>
        <div style="max-height: 400px; overflow-y: auto; padding-right: 10px; padding-left: 10px;">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>STT</th>
                <th scope="col">Mã SV</th>
                <th scope="col">Họ Tên</th>
                <th scope="col">Tên phiếu</th>
                <th scope="col">Ngày gửi</th>
                <th scope="col">Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of listSinhVienDanhGia.lstSinhVien; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ p.Ma_sv }}</td>
                <td>{{ p.Ho_ten }}</td>
                <td>{{ p.Ten_phieu }}</td>
                <td>{{ !p.Ngay_gui ? '' : p.Ngay_gui | date:'dd/MM/yyyy hh:mm' }}</td>
                <td>{{ !p.Da_danh_gia ? 'Chưa đánh giá' : 'Đã đánh giá' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <div class="modal-footer" style="height: 50px; padding-top: 10px;">
      <button type="button" class="btn btn-sm btn-danger" (click)="modal.close('Save click')"> 
        <i class="far fa-times-circle"></i> Đóng
      </button>
    </div>
  </ng-template>
  