<section class="content-header" style="padding:5px !important">
  <div class="container-fluid">
    <div class="row mb2">
      <div class="col-md-6">
        <ol class="breadcrumb ">
          <li class="breadcrumb-item"><a [routerLink]="['/']"><i class="fas fa-home"></i>Trang chủ</a></li>
          <li class="breadcrumb-item active">Tra cứu phân phối</li>
        </ol>
      </div>
      <div class="col-md-6">

      </div>
    </div>
  </div>
</section>



<ngb-tabset [destroyOnHide]="false" style="font-weight: bold;">
  <ngb-tab>
    <ng-template ngbTabTitle>Tra cứu phân phối theo đối tượng</ng-template>
    <ng-template ngbTabContent>
      <section class="content cus-body">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <label>Loại đối tượng</label>
                <select name="Category" class="form-control" id="Loai_ft" (change)="LoaidtChange()">
                  <option value="-1">Chọn loại đối tượng</option>
                  <option value="1">Sinh viên</option>
                  <option value="2">Giáo viên</option>
                  <option value="3">Doanh nghiệp</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Đợt đánh giá</label>
                <select name="" class="form-control" id="Dot_ft">
                  <option value="-1">Chọn đợt đánh giá</option>
                  <option *ngFor="let p of ListSeason" [value]="p.ID_dot_danh_gia">{{p.Ten_dot}}</option>
                </select>
              </div>
              <div class="col-md-3" *ngIf="khaosat_ft">
                <label>Loại tra cứu</label>
                <select name="" class="form-control" id="khaosat_ft">
                  <option value="0">Đánh giá giảng viên</option>
                  <option value="1">Khảo sát giảng viên</option>
                </select>
              </div>
              <div class="col-md-3" style="padding-top: 30px;">
                <button style="float: right;" class="btn-success btn-sm" (click)="Filter()"><i
                    class="fas fa-search"></i><span style="font-weight: bold;"> Tìm kiếm</span></button>
                <button *ngIf="isShowLink" style="float: right; margin-right: 5px;" class="btn-success btn-sm" (click)="Export()"><i
                    class="fas fas fa-file-excel"></i><span style="font-weight: bold;"> Xuất file địa chỉ</span></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content cus-body" id="filter">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 ">
                <label class="col-md-10">Hệ:</label>
                <select class="form-control" id="He" [(ngModel)]="He" (change)="HeChange()">
                  <option [value]="0" selected>Tất cả</option>
                  <option *ngFor="let p of ListHe" [value]="p.ID_he">{{p.Ten_he}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="col-md-10">Khoa:</label>
                <select class="form-control" id="Khoa" [(ngModel)]="Khoa" (change)="KhoaChange()">
                  <option [value]="0" selected>Tất cả</option>
                  <option *ngFor="let p of ListKhoa2" [value]="p.ID_khoa">{{p.Ten_khoa}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="col-md-10">Khoá học:</label>
                <select class="form-control" id="KhoaHoc" [(ngModel)]="KhoaHoc" (change)="KhoahocChange()">
                  <option [value]="0" selected>Tất cả</option>
                  <option *ngFor="let p of ListKhoaHoc2" [value]="p.Khoa_hoc">{{p.Khoa_hoc}}</option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="col-md-10">Chuyên ngành</label>
                <select class="form-control" id="ChuyenNganh"  [(ngModel)]="ChuyenNganh" (change)="ChuyenNganhChange()">
                  <option [value]="0" selected>Tất cả</option>
                  <option *ngFor="let p of ListChuyenNganh2" [value]="p.ID_chuyen_nganh">{{p.Chuyen_nganh}}</option>
                   
                </select>
            </div>
              <div class="col-md-3">
                <label class="col-md-10">Lớp học:</label>
                <select class="form-control" id="Lop" [(ngModel)]="Lop">
                  <option [value]="0" selected>Tất cả</option>
                  <option *ngFor="let p of ListLop2" [value]="p.ID_lop">{{p.Ten_lop}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="content cus-body" id="Search">
        <div class="card">
          <div class="card-body">
            <div class="tab-content">
              <div class="active tab-pane">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive dataTables_wrapper container-fluid dt-bootstrap4 cus-fs">
                      <table datatable id="example" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        class=" table-bordered dataTables_wrapper no-footer ">
                        <thead>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td style="display: none;"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let p of TableData; index as i">
                            <td style="text-align: center;">{{ i+1 }}</td>
                            <td>{{ p.Ma_doi_tuong }}</td>
                            <td>{{ p.Ho_ten }}</td>
                            <td style="display: none;">{{ p.ID_he }}</td>
                            <td style="display: none;">{{ p.ID_khoa }}</td>
                            <td style="display: none;">{{ p.Khoa_hoc }}</td>
                            <td style="display: none;">{{ p.ID_lop }}</td>
                            <td>{{ p.So_phieu }}</td>
                            <td>{{ p.Da_danh_gia }} / {{p.Tong_doi_tuong}}</td>
                            <td>
                              <a [routerLink]="['/admin/SearchObjectList']" target="_blank"
                                [queryParams]="{ID_doi_tuong: p.ID_doi_tuong, ID_Dot:p.ID_dot_danh_gia,Category:p.Category}"
                                queryParamsHandling="merge" class="btn btn-success btn-sm"><i
                                  class="fas fa-list-alt"></i></a>
                                  <button *ngIf="isShowLink" title="Copy địa chỉ đánh giá" (click)="CopyLink(p.Login_Url)" class="btn btn-primary btn-sm" style="margin-left:  5px;">
                                    <i class="fa fa-link"></i>
                                  </button>
                            </td>
                          </tr>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>
  </ngb-tab>
  <ngb-tab style="font-weight: bold;" title="Tra cứu phân phối theo sinh viên, Giáo viên">
    <ng-template ngbTabContent>
      <app-search-student-teacher>
      </app-search-student-teacher>

    </ng-template>
  </ngb-tab>
</ngb-tabset>
