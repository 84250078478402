import { Component, OnInit, ViewChild } from "@angular/core";
import { AppService } from "src/app/Utils/service/app.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SearchObjectService } from "src/app/Utils/service/search-object.service";
import { DataTableDirective } from "angular-datatables";
import { Subject, forkJoin } from "rxjs";
import { common } from "src/app/app.common";
import { SeasonService } from "src/app/Utils/service/season.service";
import { ReportService } from "src/app/Utils/service/report.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BaseComponent } from "../../../base/base.component";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportService } from "src/app/Utils/service/export.service";
@Component({
  selector: "app-search-student-teacher",
  templateUrl: "./search-student-teacher.component.html",
  styleUrls: ["./search-student-teacher.component.scss"],
})
export class SearchStudentTeacherComponent implements OnInit {
  dtOptions: DataTables.Settings;
  dataTable: any = [];
  Res: any;
  Req: any;
  TableData: any;
  constructor(
    public router: Router,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    public seasonService: SeasonService,
    public searchObjectService: SearchObjectService,
    public ReportService: ReportService,
    public exportService: ExportService,
    public toastr: ToastrService
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  TieuDe: string;
  Title: string = "Đang tải danh sách thông báo...";
  TitleClass: string = "spinner-border text-muted";
  public Ma_sv: string = "";
  public Ma_cb: string = "";
  public Da_danh_gia: any = 0;
  public ID_dot_danh_gia: any = 0;

  public ID_he: any = 0;
  public ID_khoa: any = 0;
  public Khoa_hoc: any = 0;
  public ID_lop: any = 0;
  public ID_phieu_danh_gia: any = 0;
  public ListSeason: any;
  public ListPhieu: any;
  public ListPhieuDanhGia: any;
  public ListPhieuDanhGia2: any;
  public ListHe: any;
  public ListKhoa: any;
  public ListKhoa2: any;
  public ListKhoaHoc: any;
  public ListKhoaHoc2: any;
  public ListChuyenNganh: any;
  public ListChuyenNganh2: any;
  public ListLop: any;
  public ListLop2: any;
  public He: any = 0;
  public Khoa: any = 0;
  public KhoaHoc: any = 0;
  public ChuyenNganh: any = 0;
  public Lop: any = 0;
  Token: any;
  public arrNumberPage: any = [];
  arrNumberPage_chil: any = [];
  numberPage: any;
  page: any = 1;
  public pageSize: any = 10;
  public pageSizes: any = [10, 20, 50, 100, 200, 500, 1000, "Tất cả"];
  public getNull = false;
  public totalItem: number;
  totalItemFilter: any;
  isDisplay: boolean = true;
  submitted = false;
  closeResult: string;
  dataPopup: any = {};
  public com: common;
  IDRecord: any;
  ID_gui: any
  ID_dot: any

  ngOnInit() {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token;
    this.getList();
    this.getCountDotPhieu();

    this.getDotDanhGia();
    this.getPhieuDanhGia();

    this.seasonService.GetChuyenNganh(a.access_token)
      .subscribe(z => {
        this.ListChuyenNganh = z.Data;
        this.seasonService.GetKhoa(a.access_token)
          .subscribe(z => {
            this.ListKhoa = z.Data;
            this.seasonService.GetKhoaHoc(a.access_token)
              .subscribe(z => {
                this.ListKhoaHoc = z.Data;
                this.seasonService.getListClass(a.access_token)
                  .subscribe(z => {
                    this.ListLop = z.Data; this.HeChange()
                    this.seasonService.GetHe(a.access_token)
                      .subscribe(z => {
                        this.ListHe = z.Data
                        this.spinner.hide()
                      });
                  });
              });
          });
      });


  }
  getDotDanhGia() {
    this.seasonService.getListSeason(this.Token).subscribe((z) => {
      this.ListSeason = z.Data;


    });
  }
  getPhieuDanhGia() {
    this.ReportService.PhieuDanhGiaTheoDot(this.ID_dot_danh_gia, this.Token).subscribe((z) => {
      const ls = new Array();
      if (z.Data) {
        const uniqueSurveys: Set<number> = new Set();
        for (var i = 0; i < z.Data.length; i++) {
          const survey = z.Data[i].Survey;
          
          if (!uniqueSurveys.has(survey.ID_phieu_danh_gia)) {
            uniqueSurveys.add(survey.ID_phieu_danh_gia);
            ls.push(survey);
          }
        }
      }
      this.ListPhieuDanhGia = ls;



    });
  }


  HeChange() {
    let ID_he = this.He;
    if (ID_he > 0) {
      this.ListKhoa2 = this.ListKhoa.filter(g => g.ID_he == ID_he);
      this.ListKhoaHoc2 = this.ListKhoaHoc.filter(g => g.ID_he == ID_he);
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.ID_he == ID_he);
      this.ListLop2 = this.ListLop.filter(g => g.ID_he == ID_he)

    } else {
      this.ListKhoa2 = [];
      this.ListKhoaHoc2 = [];
      this.ListChuyenNganh2= [];
      this.ListLop2 = []
    }
  }
  open(content, sizm, Loai, Data) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: sizm }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    if (Loai == "Delete") {
      this.ID_gui = Data.ID_gui;
    }
  }
  DotChange() {
    this.getPhieuDanhGia();

  }
  KhoaChange() {
    let ID_khoa = this.Khoa;
    if (ID_khoa > 0) {
      this.ListKhoaHoc2 = this.ListKhoaHoc.filter(g => g.ID_khoa == ID_khoa);
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.ID_khoa == ID_khoa);
      this.ListLop2 = this.ListLop.filter(g => g.ID_khoa == ID_khoa)
    } else {
      this.ListKhoaHoc2 = [];
      this.ListChuyenNganh2 = [];
      this.ListLop2 = []
    }
  }

  KhoahocChange() {
    let Khoa_hoc = this.KhoaHoc;
    if (Khoa_hoc > 0) {
      this.ListChuyenNganh2 = this.ListChuyenNganh.filter((g) => g.Khoa_hoc == Khoa_hoc);
      this.ListLop2 = this.ListLop.filter(g => g.Khoa_hoc == Khoa_hoc)
    } else {
      this.ListChuyenNganh2 = [];
      this.ListLop2 = []
    }
  }
  ChuyenNganhChange() {
    let Khoa_hoc = this.KhoaHoc;
    let ID_chuyen_nganh = this.ChuyenNganh;
    if (ID_chuyen_nganh > 0) {
      this.ListLop2 = this.ListLop.filter((g) => g.ID_chuyen_nganh == ID_chuyen_nganh && g.Khoa_hoc == Khoa_hoc);
    } else {
      this.ListLop2 = [];
    }
  }
  ngOnDestroy(): void {
    $.fn["dataTable"].ext.search.pop();
  }

  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  createNumberPage(totalItem, pageSize) {
    let numberPage = 0;
    let arrNumberPage = [];
    if (totalItem % pageSize == 0) {
      numberPage = Math.floor(totalItem / pageSize);
    } else {
      numberPage = Math.floor(totalItem / pageSize) + 1;
    }
    for (var i = 1; i <= numberPage; i++) {
      arrNumberPage.push(i);
    }
    let arrNumberPage_chil = [];
    if (arrNumberPage.length > 4) {
      for (var i = 1; i <= 4; i++) {
        arrNumberPage_chil.push(i);
      }
    } else {
      arrNumberPage_chil = arrNumberPage;
    }
    return {
      numberPage: numberPage,
      arrNumberPage_chil: arrNumberPage_chil,
    };
  }

  buttonFilter() {
    this.isDisplay = !this.isDisplay;
  }

  refesh() {
    this.Ma_sv = "";
    this.Ma_cb = "";
    this.Da_danh_gia = 0;
    this.ID_dot_danh_gia = 0;
    this.ID_phieu_danh_gia = 0;
    this.He = 0;
    this.Khoa = 0;
    this.KhoaHoc = 0;
    this.Lop = 0;
    this.getList();
  }

  handlePageChange(event) {
    if (event == "pre") {
      this.page = this.page - 1;
    } else if (event == "next") {
      this.page = this.page + 1;
    } else {
      this.page = event;
      this.arrNumberPage_chil = [];
      for (var i = event - 3; i <= event + 3; i++) {
        if (i > 0 && i <= this.numberPage) {
          this.arrNumberPage_chil.push(i);
        }
      }
    }
  }

  XuatExcel() {
    this.spinner.show();
    let dataFilter = {

      Ma_sv: this.Ma_sv.trim(),
      Ma_cb: this.Ma_cb.trim(),
      Da_danh_gia: this.Da_danh_gia == 0 ? "false" : "true",
      ID_phieu: this.ID_phieu_danh_gia,
      ID_dot: this.ID_dot_danh_gia,
      ID_he: this.He,
      ID_khoa: this.Khoa,
      Khoa_hoc: this.KhoaHoc,
      ID_lop: this.Lop
    };
    let param = {
      So_ban_ghi: 1000000,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService.Excel(param, this.Token).subscribe((z) => {
      if (z.Status == 1) {
        this.exportService.exportExcelByte(z.FileData, 'Phan_phoi_SV_GV');
        this.toastr.success("File đã được tải xuống", "Tác vụ thành công")
        this.spinner.hide();
      } else {
        this.toastr.error(z.Message)
        this.spinner.hide();
      }
    });
  }

  changPage(event) {
    this.handlePageChange(event);
    this.spinner.show();
    let dataFilter = {
      Ma_sv: this.Ma_sv ? this.Ma_sv : "",
      Ma_cb: this.Ma_cb ? this.Ma_cb : "",
      Da_danh_gia: this.Da_danh_gia == 0 ? "false" : "true",
      ID_phieu: this.ID_phieu_danh_gia ? this.ID_phieu_danh_gia : 0,
      ID_dot: this.ID_dot_danh_gia ? this.ID_dot_danh_gia : 0,
      ID_he: this.ID_he ? this.ID_he : 0,
      ID_khoa: this.ID_khoa ? this.ID_khoa : 0,
      Khoa_hoc: this.Khoa_hoc ? this.Khoa_hoc : 0,
      ID_lop: this.ID_lop ? this.ID_lop : 0,
    };

    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };

    this.searchObjectService
      .getObject_Student_Teacher_List(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.Data;

        this.spinner.hide();
      });
  }

  xoa_dau(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ|ị/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)
    return str;
  }

  getCount() {
    this.searchObjectService.getCount(this.Token).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getCountDotPhieu() {
    let dataFilter = {
      Ma_sv: this.Ma_sv,
      Ma_cb: this.Ma_cb,
      Da_danh_gia: 0,
      ID_phieu: this.ID_phieu_danh_gia,
      ID_dot: this.ID_dot_danh_gia,
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      ID_lop: 0
    };
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService.getCountDotPhieu(this.Token, param).subscribe((z) => {
      this.totalItem = z.Tong_so_ban_ghi;
    });
  }

  getList() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      Ma_sv: this.Ma_sv,
      Ma_cb: this.Ma_cb,
      Da_danh_gia: 0,
      ID_phieu: 0,
      ID_dot: 0,
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      ID_lop: 0
    };
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.searchObjectService
      .getObject_Student_Teacher_List(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.Data;

        this.searchObjectService
          .getCountFilter(param, this.Token)
          .subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
      });
  }

  handlePageSizeChange(event) {
    if (event.target.value == "Tất cả") {
      this.pageSize = 100000;
    } else {
      this.pageSize = event.target.value;
    }
    this.getList();
  }

  searchObject(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  Search() {
    this.spinner.show();
    this.page = 1;
    this.arrNumberPage = [];
    this.arrNumberPage_chil = [];
    let dataFilter = {
      Ma_sv: this.Ma_sv,
      Ma_cb: this.Ma_cb,
      Da_danh_gia: this.Da_danh_gia == 0 ? "false" : "true",
      ID_phieu: this.ID_phieu_danh_gia,
      ID_dot: this.ID_dot_danh_gia,
      ID_he: this.He,
      ID_khoa: this.Khoa,
      Khoa_hoc: this.KhoaHoc,
      ID_lop: this.Lop
    };
    let param = {
      So_ban_ghi: this.pageSize,
      Trang: this.page,
      dataFilter: dataFilter,
    };
    this.getCountDotPhieu();
    this.searchObjectService
      .getObject_Student_Teacher_List(param, this.Token)
      .subscribe((z) => {
        this.dataTable = z.Data;

        this.searchObjectService
          .getCountFilter(param, this.Token)
          .subscribe((z) => {
            this.totalItemFilter = z.Tong_so_ban_ghi_filter;
            this.numberPage = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).numberPage;
            this.arrNumberPage_chil = this.createNumberPage(
              z.Tong_so_ban_ghi_filter,
              this.pageSize
            ).arrNumberPage_chil;
            this.spinner.hide();
          });
      });
  }
  Delete() {
    this.searchObjectService.Delete(this.ID_gui, this.Token).subscribe((res) => {
      if (res.Status == 2) {
        this.toastr.warning(res.Message);
      } else if (res.Status == 1) {
        this.toastr.success(res.Message);
      } else if (res.Status == 4) {
        this.toastr.error('Xoá thất bại!');
      }
      this.getList();
      this.modalService.dismissAll('');
      this.datatableElement.dtInstance.then(
        (dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        }
      );
    },
      (err) => {

        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem('UserInfo');
          this.router.navigate(['/login']);
        }
        if (err.status == 401) {
          this.toastr.warning(
            'Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!'
          );
        }
      })
  }

  filterById(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }
}
