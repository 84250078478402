import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  UrlFooTer:any;
  TextFooTer:any;
  TextFooTerName:any;
  version:any;
  versionDetail:any;
    constructor(
      @Inject(AppConfig)
      private readonly appConfig: AppConfiguration,
      private http: HttpClient
    ) { }
  

    ngOnInit() {
      this.UrlFooTer = this.appConfig.UrlFooTer
      this.TextFooTer = this.appConfig.TextFooTer
      this.TextFooTerName =this.appConfig.TextFooTerName
      this.http.get<any>('assets/version.json').subscribe(data => {
        this.version=data.version 
        
    })
   
  }
  
}
